import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const MSALLoginRequest = Object.freeze({
    scopes: ["openid", "profile", "user.read"],
});

export const MSALInitialConfig = {
    auth: {
        clientId: process.env.GATSBY_AZURE_CLIENT_ID!, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${process.env.GATSBY_AZURE_TENANT_ID!}`, // Replace the placeholder with your tenant subdomain
        redirectUri: typeof window === "undefined" ? process.env.DEPLOY_PRIME_URL! : window.location.origin, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: process.env.GATSBY_AZURE_REDIRECT_URL!, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the api code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        /// Solution below from: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206#issuecomment-2284593117
        //
        // acquireTokenSilent only looks at the expiration time of the access token to determine when it needs
        // to refresh the tokens (because it wrongly assumes all tokens have the same lifetime).
        // Microsoft changed their backend and the access token now have a random lifespan between 60 and 90 minutes
        // while the ID token has a fixed 1 hour lifetime (at least by default)
        // This means acquireTokenSilent can return expired ID tokens from cache for at most 30 minutes
        // To work around this, we set the renewal offset to 35 minutes before the accessToken expires
        // which will translate to 5 to 35 minutes before the ID token expires
        // Ref: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
        tokenRenewalOffsetSeconds: 35 * 60,
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) return;
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const API_DATASOURCE_VIEW_ID = "37fd6428-9723-4849-b831-8714728d00b5";
export const API_FORECAST_VIEW_ID = "bd3cea41-618c-4587-a3a5-8a208e5db2ac";
export const API_BANNER_VIEW_ID = "4e6e6c50-9cee-48c6-b81c-d4a43e3bf7ed";
export const CIRCANA_KEY = "Date_Through_Circana";
export const COGNOS_KEY = "Date_Through_Cognos";
export const IDIG_KEY = "Date_Through_IDIG";
