import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Workbook } from "../types/tableau-api";
import { TableauForecastData } from "../services/api/api.types";

type PerformanceData = Record<string, { ptp?: string; avp?: string }>;

export interface DataSlice {
    dataSource: Record<string, string>;
    forecast: TableauForecastData;
    workbooks: Workbook[];
    banner: { YTD: PerformanceData; MTD: PerformanceData };
    searchTerms: string;
    dataSourceLoaded: boolean;
    forecastLoaded: boolean;
    workbooksLoaded: boolean;
    bannerLoaded: boolean;
}

export const dataSlice = createSlice({
    name: "data",
    initialState: (): DataSlice => ({
        dataSource: {},
        forecast: {
            shipment: "",
            depletion: "",
        },
        workbooks: [],
        banner: { YTD: {}, MTD: {} },
        searchTerms: typeof window !== "undefined" ? (window.sessionStorage.getItem("searchTerms") ?? "") : "",
        // Simple flags to indicate if the store has initialized the related data since application load.
        dataSourceLoaded: false,
        forecastLoaded: false,
        workbooksLoaded: false,
        bannerLoaded: false,
    }),
    reducers: {
        setDataSource: (state, action: PayloadAction<DataSlice["dataSource"]>) => {
            state.dataSource = action.payload;
            state.dataSourceLoaded = true;
        },
        setForecast: (state, action: PayloadAction<DataSlice["forecast"]>) => {
            state.forecast = action.payload;
            state.forecastLoaded = true;
        },
        setWorkbooks: (state, action: PayloadAction<DataSlice["workbooks"]>) => {
            state.workbooks = action.payload;
            state.workbooksLoaded = true;
        },
        setBanner: (state, action: PayloadAction<DataSlice["banner"]>) => {
            state.banner = action.payload;
            state.bannerLoaded = true;
        },
    },
});
export const { setDataSource, setForecast, setWorkbooks, setBanner } = dataSlice.actions;
export default dataSlice.reducer;
