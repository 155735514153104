import React from "react";

export interface ISidebarContext {
    sidebarOpen: boolean;
    toggleSidebar: () => void;
}

// Boostrap value.  Expected to be used in a string for applying a bootstrap class such as "d-{}-none".  At or above this breakpoint, the sidebar is considered "always open"
export const SIDEBAR_BREAKPOINT = "xl";

export const SidebarContext = React.createContext<ISidebarContext>({
    sidebarOpen: false,
    toggleSidebar: () => {},
});

export const SidebarProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [sidebarOpen, setSidebarOpen] = React.useState(() => {
        if (typeof window === "undefined") return false;
        return window.matchMedia("(min-width: 1199px)").matches; // Bootstrap Medium ends at 768px.
    });

    const providerState: ISidebarContext = {
        sidebarOpen,
        toggleSidebar: () => setSidebarOpen(!sidebarOpen),
    };

    return <SidebarContext.Provider value={providerState}>{props.children}</SidebarContext.Provider>;
};
